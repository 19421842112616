// extracted by mini-css-extract-plugin
export var bgGrey100 = "SaleFlag-module--bg-grey-100--9e8f6";
export var bgGrey150 = "SaleFlag-module--bg-grey-150--a9f6c";
export var bgGrey200 = "SaleFlag-module--bg-grey-200--0f6c9";
export var bgGrey300 = "SaleFlag-module--bg-grey-300--25ac0";
export var bgGrey400 = "SaleFlag-module--bg-grey-400--3468d";
export var bgGrey500 = "SaleFlag-module--bg-grey-500--351ce";
export var bgGrey600 = "SaleFlag-module--bg-grey-600--013bd";
export var bgGrey700 = "SaleFlag-module--bg-grey-700--9425b";
export var bgGrey800 = "SaleFlag-module--bg-grey-800--3a268";
export var bgGrey900 = "SaleFlag-module--bg-grey-900--84add";
export var onSaleFlagText = "SaleFlag-module--onSaleFlagText--a3a5a";
export var saleFlag = "SaleFlag-module--saleFlag--c43a1";
export var textGrey100 = "SaleFlag-module--text-grey-100--3dee3";
export var textGrey150 = "SaleFlag-module--text-grey-150--6305b";
export var textGrey200 = "SaleFlag-module--text-grey-200--38588";
export var textGrey300 = "SaleFlag-module--text-grey-300--7bb49";
export var textGrey400 = "SaleFlag-module--text-grey-400--65624";
export var textGrey500 = "SaleFlag-module--text-grey-500--ad24e";
export var textGrey600 = "SaleFlag-module--text-grey-600--f8b02";
export var textGrey700 = "SaleFlag-module--text-grey-700--3e0f4";
export var textGrey800 = "SaleFlag-module--text-grey-800--a6088";
export var textGrey900 = "SaleFlag-module--text-grey-900--6541b";