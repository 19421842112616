import React, { Fragment, useRef, useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { v4 as uuidv4 } from "uuid"

import { selectFirstVariant } from "../../../utils"
import { Section } from "../../UI/Common"
import Card from "./Card"
import { PrevButton, NextButton } from "../../UI/Slider/SliderArrows"
import { infiniteScrollSettings } from "../../UI/Slider/Slide"
import MarketingContent from "./MarketingContent"
import * as style from "./CarouselWithMarketingContent.module.scss"

const CarouselWithMarketingContent = ({
  marketingContent,
  products,
  config,
}) => {
  const { padding } = config || {}
  const [slide, setSlide] = useState(0)
  const slides = []

  const filteredProducts = products?.filter(item => item.product !== null)

  filteredProducts?.forEach(item => {
    const product = item.product || item

    const excludedProducts = process.env.EXCLUDED_PRODUCTS.split(",")

    if ((product.type === "SIMPLE" || product.nodeType === 'SimpleProduct') && !excludedProducts.includes(String(product.databaseId))) {
      slides.push(product)
      return null
    } else if ( ( product.type === "VARIABLE" || product.nodeType === 'VariableProduct' ) && !excludedProducts.includes(String(product.databaseId)) ) {
      const selectedVariant = selectFirstVariant(product)
      selectedVariant && slides.push(selectedVariant)
      return null
    } else if(!excludedProducts.includes(String(product.databaseId))) {
      console.warn(`FIXME, I should not be here, product type was ${product.type}, ${product.nodeType}, for ${product.name}`)
    }
    return null
  })

  const slider = useRef(null)
  const { title, paragraph, buttons } = marketingContent
  slides.splice(0, 0, { title, paragraph, buttons })

  return (
    <Section className={style.sectionCls} padding={padding}>
      <div className={style.wrapper}>
        <div className={style.innerWrapper}>
          <div>
            <PrevButton ref={slider} show={[slide, setSlide]} />
          </div>
          <div>
            <NextButton ref={slider} show={[slide, setSlide]} />
          </div>
          <Slider ref={slider} {...infiniteScrollSettings}>
            {slides.map((item, index) => {
              if (index === 0) {
                return (
                  marketingContent !== null && (
                    <MarketingContent
                      key={uuidv4()}
                      marketingContent={marketingContent}
                    />
                  )
                )
              } else {
                return (
                  <Fragment key={uuidv4()}>
                    <Card product={item} />
                  </Fragment>
                )
              }
            })}
          </Slider>
        </div>
      </div>
    </Section>
  )
}

export default CarouselWithMarketingContent
