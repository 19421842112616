// extracted by mini-css-extract-plugin
export var bgGrey100 = "CarouselWithMarketingContent-module--bg-grey-100--ebb49";
export var bgGrey150 = "CarouselWithMarketingContent-module--bg-grey-150--230c5";
export var bgGrey200 = "CarouselWithMarketingContent-module--bg-grey-200--c39f9";
export var bgGrey300 = "CarouselWithMarketingContent-module--bg-grey-300--0c5a1";
export var bgGrey400 = "CarouselWithMarketingContent-module--bg-grey-400--ec6ce";
export var bgGrey500 = "CarouselWithMarketingContent-module--bg-grey-500--d4e2e";
export var bgGrey600 = "CarouselWithMarketingContent-module--bg-grey-600--b0167";
export var bgGrey700 = "CarouselWithMarketingContent-module--bg-grey-700--481c0";
export var bgGrey800 = "CarouselWithMarketingContent-module--bg-grey-800--3a79d";
export var bgGrey900 = "CarouselWithMarketingContent-module--bg-grey-900--5b344";
export var descriptionCls = "CarouselWithMarketingContent-module--descriptionCls--636ad";
export var h2Cls = "CarouselWithMarketingContent-module--h2Cls--b58c7";
export var innerWrapper = "CarouselWithMarketingContent-module--innerWrapper--a24d1";
export var marketingCardCls = "CarouselWithMarketingContent-module--marketingCardCls--79fcb";
export var sectionCls = "CarouselWithMarketingContent-module--sectionCls--050ba";
export var slideBtnCls = "CarouselWithMarketingContent-module--slideBtnCls--8c349";
export var textGrey100 = "CarouselWithMarketingContent-module--text-grey-100--ce768";
export var textGrey150 = "CarouselWithMarketingContent-module--text-grey-150--626dc";
export var textGrey200 = "CarouselWithMarketingContent-module--text-grey-200--838a9";
export var textGrey300 = "CarouselWithMarketingContent-module--text-grey-300--aa6b3";
export var textGrey400 = "CarouselWithMarketingContent-module--text-grey-400--081cc";
export var textGrey500 = "CarouselWithMarketingContent-module--text-grey-500--5a2ba";
export var textGrey600 = "CarouselWithMarketingContent-module--text-grey-600--5559a";
export var textGrey700 = "CarouselWithMarketingContent-module--text-grey-700--b58e0";
export var textGrey800 = "CarouselWithMarketingContent-module--text-grey-800--f8d22";
export var textGrey900 = "CarouselWithMarketingContent-module--text-grey-900--39bf0";
export var wrapper = "CarouselWithMarketingContent-module--wrapper--8db47";