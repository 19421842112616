import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Link } from "../Common"
import { getProductLink } from "../../../utils"
import {
  slideHeadCls,
  linkCls,
  productNameCls,
  oldPriceCls,
  salePriceCls,
  priceCls,
  slideImgCls,
  externalSlideImgCls,
  externalSlideBodyCls,
} from "./Slide.module.scss"

export const SlideHeader = ({ product }) => {
  const { name, onSale, price, regularPrice } = product

  let stringPrice = price;
  let stringRegularPrice = regularPrice;
  if(typeof price !== 'string' || typeof regularPrice !== 'string') {
    stringPrice = "£"+ price
    stringRegularPrice = "£"+ regularPrice
  }

  return (
    <div className={slideHeadCls}>
      <div className="pl-md-3">
        <Link className={linkCls} link={getProductLink(product)}>
          <p className={productNameCls}>{name}</p>
        </Link>
      </div>
      <div className="pl-md-3">
        {onSale ? (
          <div className="d-flex flex-row-reverse justify-content-end">
            <p className={oldPriceCls}>{stringRegularPrice}</p>
            <p className={salePriceCls}>{stringPrice}</p>
          </div>
        ) : (
          <p className={priceCls}>{stringPrice}</p>
        )}
      </div>
    </div>
  )
}

export const SlideBody = ({ image }) => {
    return (
      <GatsbyImage
        className={slideImgCls}
        image={getImage(image.node?.localFile)}
        imgStyle={{
          objectFit: "contain !important",
          objectPosition: "50% 50%",
        }}
        alt={image.node?.altText}
      />
    )
}

export const ExternalSlideBody = ({ product }) => {
  const {
    imageSrc,
    imageAlt,
  } = product

  return (
    <>
      <div className={externalSlideBodyCls}>
        <div  className={externalSlideImgCls}>
          <img src={imageSrc} alt={imageAlt} />
        </div>
      </div>
    </>
  )
}


export const infiniteScrollSettings = {
  dots: false,
  arrows: false,
  adaptiveHeight: true,
  className: "variable-width",
  centerMode: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  autoplaySpeed: 2000,
  slidesToScroll: 2,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 799,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}
