import { graphql } from "gatsby"
import React from "react"

import CarouselWithMarketingContent from "./CarouselWithMarketingContent"

export const fragment = graphql`
  fragment CarouselWithMarketingContentFragment on WpPage_Flexlayouts_FlexibleLayouts_CarouselWithMarketingContent {
    sectionHeading
    text
    products {
      product {
        ...SimpleProductQuery
        ...VariableProductQuery
      }
    }
    marketingContent {
      title
      paragraph
      buttons {
        link {
          title
          target
          url
        }
      }
    }
    config {
      padding {
        bottom {
          bottomDesktop
          bottomTablet
          bottomMobile
        }
        top {
          topDesktop
          topTablet
          topMobile
        }
      }
    }
  }
`

export const ACFCarouselWithMarketingContent = ({
  sectionHeading,
  text,
  products,
  config,
  marketingContent,
}) => {
  return (
    <CarouselWithMarketingContent
      sectionHeading={sectionHeading}
      text={text}
      marketingContent={marketingContent}
      products={products}
      config={config}
    />
  )
}
