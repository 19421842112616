// extracted by mini-css-extract-plugin
export var bgGrey100 = "Slide-module--bg-grey-100--e527f";
export var bgGrey150 = "Slide-module--bg-grey-150--3bc6e";
export var bgGrey200 = "Slide-module--bg-grey-200--430eb";
export var bgGrey300 = "Slide-module--bg-grey-300--31be6";
export var bgGrey400 = "Slide-module--bg-grey-400--5da25";
export var bgGrey500 = "Slide-module--bg-grey-500--fdffb";
export var bgGrey600 = "Slide-module--bg-grey-600--c93d4";
export var bgGrey700 = "Slide-module--bg-grey-700--8d1b7";
export var bgGrey800 = "Slide-module--bg-grey-800--6c79a";
export var bgGrey900 = "Slide-module--bg-grey-900--72a5c";
export var externalSlideBodyCls = "Slide-module--externalSlideBodyCls--98e67";
export var externalSlideImgCls = "Slide-module--externalSlideImgCls--35395";
export var largeSlideCls = "Slide-module--largeSlideCls--c71fe";
export var linkCls = "Slide-module--linkCls--87d7b";
export var marketingSlideCls = "Slide-module--marketingSlideCls--6ae86";
export var oldPriceCls = "Slide-module--oldPriceCls--58f8f";
export var priceCls = "Slide-module--priceCls--44efb";
export var productNameCls = "Slide-module--productNameCls--27a78";
export var salePriceCls = "Slide-module--salePriceCls--bd36f";
export var slideHeadCls = "Slide-module--slideHeadCls--22b34";
export var slideImgCls = "Slide-module--slideImgCls--cda17";
export var slideTitleCls = "Slide-module--slideTitleCls--dec71";
export var textGrey100 = "Slide-module--text-grey-100--892d0";
export var textGrey150 = "Slide-module--text-grey-150--1247d";
export var textGrey200 = "Slide-module--text-grey-200--07eaf";
export var textGrey300 = "Slide-module--text-grey-300--b1bdf";
export var textGrey400 = "Slide-module--text-grey-400--641b7";
export var textGrey500 = "Slide-module--text-grey-500--cb7e4";
export var textGrey600 = "Slide-module--text-grey-600--a1c09";
export var textGrey700 = "Slide-module--text-grey-700--78812";
export var textGrey800 = "Slide-module--text-grey-800--a1afd";
export var textGrey900 = "Slide-module--text-grey-900--4bdbf";