import React from "react"
import * as style from "./SaleFlag.module.scss"

const SaleFlag = (top='unset',left='unset',right='unset',bottom='unset') => {
  return (
    <div style={{top:top,left:left,right:right,bottom:bottom}}className={style.saleFlag}>
      <p className={style.onSaleFlagText}>Sale</p>
    </div>
  )
}

export default SaleFlag
