import React, { forwardRef } from "react"

import LeftArrow from "./assets/icon-arrow-left-dark.inline.svg"
import RightArrow from "./assets/icon-arrow-right-dark.inline.svg"

import {
  sliderArrowBtnBkgdCls,
  nextBtnArrowCls,
} from "./SliderArrows.module.scss"

const PrevButton = forwardRef(({ show }, ref) => {
  const handlePrev = (show, ref) => {
    const [slide, setSlide] = show
    ref.current.slickPrev()
    setSlide(slide - 1)
  }

  return (
    <div
      className={sliderArrowBtnBkgdCls}
      role="button"
      tabIndex={0}
      onClick={() => handlePrev(show, ref)}
      onKeyPress={() => handlePrev(show, ref)}>
        <LeftArrow />
    </div>
  )
})

const NextButton = forwardRef(({ show }, ref) => {
  const handleNext = (show, ref) => {
    const [slide, setSlide] = show
    ref.current.slickNext()
    setSlide(slide + 1)
  }

  return (
    <div
      className={`${sliderArrowBtnBkgdCls} ${nextBtnArrowCls}`}
      role="button"
      tabIndex={0}
      onClick={() => handleNext(show, ref)}
      onKeyPress={() => handleNext(show, ref)}>
      <RightArrow />
    </div>
  )
})

const PrevArrow = forwardRef(({ show }, ref) => {
  const handlePrev = (show, ref) => {
    const [slide, setSlide] = show
    ref.current.slickPrev()
    setSlide(slide - 1)
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => handlePrev(show, ref)}
      onKeyPress={() => handlePrev(show, ref)}>
        <LeftArrow />
    </div>
  )
})

const NextArrow = forwardRef(({ show }, ref) => {
  const handleNext = (show, ref) => {
    const [slide, setSlide] = show
    ref.current.slickNext()
    setSlide(slide + 1)
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => handleNext(show, ref)}
      onKeyPress={() => handleNext(show, ref)}>
      <RightArrow />
    </div>
  )
})

export { PrevButton, NextButton, PrevArrow, NextArrow }
