// extracted by mini-css-extract-plugin
export var arrowColor = "#ffb206";
export var bgGrey100 = "SliderArrows-module--bg-grey-100--7fbc4";
export var bgGrey150 = "SliderArrows-module--bg-grey-150--4418a";
export var bgGrey200 = "SliderArrows-module--bg-grey-200--89af7";
export var bgGrey300 = "SliderArrows-module--bg-grey-300--a4099";
export var bgGrey400 = "SliderArrows-module--bg-grey-400--aed3a";
export var bgGrey500 = "SliderArrows-module--bg-grey-500--92b0e";
export var bgGrey600 = "SliderArrows-module--bg-grey-600--cc474";
export var bgGrey700 = "SliderArrows-module--bg-grey-700--d05b9";
export var bgGrey800 = "SliderArrows-module--bg-grey-800--9fdc8";
export var bgGrey900 = "SliderArrows-module--bg-grey-900--6e47e";
export var nextBtnArrowCls = "SliderArrows-module--nextBtnArrowCls--c1b98";
export var sliderArrowBtnBkgdCls = "SliderArrows-module--sliderArrowBtnBkgdCls--27d2b";
export var textGrey100 = "SliderArrows-module--text-grey-100--fc327";
export var textGrey150 = "SliderArrows-module--text-grey-150--6eef9";
export var textGrey200 = "SliderArrows-module--text-grey-200--852ce";
export var textGrey300 = "SliderArrows-module--text-grey-300--46f33";
export var textGrey400 = "SliderArrows-module--text-grey-400--f2543";
export var textGrey500 = "SliderArrows-module--text-grey-500--62c60";
export var textGrey600 = "SliderArrows-module--text-grey-600--eba48";
export var textGrey700 = "SliderArrows-module--text-grey-700--926d8";
export var textGrey800 = "SliderArrows-module--text-grey-800--a930d";
export var textGrey900 = "SliderArrows-module--text-grey-900--dece6";