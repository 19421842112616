import React from "react"
import * as style from "./CarouselWithMarketingContent.module.scss"
import { LinkButton, SectionHeading } from "../../UI/Common"
import { v4 as uuidv4 } from "uuid"

const MarketingContent = ({ marketingContent }) => {
  const { title, paragraph, buttons } = marketingContent

  return (
    <div className={style.marketingCardCls}>
      <div key={uuidv4()}>
        <SectionHeading text={title} mode="dark" alignment="center" />
        <p className={`text-light text-center p-md-3 ${style.descriptionCls}`}>
          {paragraph}
        </p>
        <div className="d-flex justify-content-center">
          {buttons &&
            buttons.length &&
            buttons.map(
              btn =>
                btn.link && (
                  <LinkButton
                    variant="outlined-light"
                    title={btn.link.title}
                    link={btn.link.url}
                    className="m-1 p-2"
                    key={uuidv4()}
                  />
                )
            )}
        </div>
      </div>
    </div>
  )
}

export default MarketingContent
